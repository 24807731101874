import { ObjectIdCreationDate } from "./DateFromObjectID";
import { getHighestRepeatingOrderStatus } from "./orderHistoryStatusFinder";
import { createItemObject } from "./ItemObjectMaker";

// Calculate loyalty count based on userOrders
function calculateLoyaltyCount(userOrders = []) {
  let loyaltyCount = 0;
  // Iterate through each order in the userOrders array
  for (const order of userOrders) {
    // Check if any product in the order has payment_status as "paid"
    const hasPaidProduct = order.product.some(
      (product) => product.payment_status === "paid"
    );

    // If at least one product is paid, increment the loyalty count
    if (hasPaidProduct) {
      loyaltyCount++;
    }
  }

  return loyaltyCount;
}

// Main function to prepare data
export const PreDataMaker = (
  source,
  price,
  formData,
  loggedInUser,
  userOrders,
  productById,
  userCart 
) => {
  // Calculate loyalty count explicitly by passing userOrders
  const loyaltyCount = calculateLoyaltyCount(userOrders);

  return {
    source,
    total: price?.total,
    tax: price?.tax,
    shipping_price: price?.shipping,
    customer: {
      name: formData.name,
      email: loggedInUser?.email,
      phone: formData?.phone,
      id: loggedInUser?._id,
      history: {
        registered_since:
          loggedInUser?.createdAt ?? ObjectIdCreationDate(loggedInUser?._id),
        loyalty_level: loyaltyCount, // Use the calculated loyalty count
        is_email_verfied: loggedInUser?.isVerified
          ? loggedInUser?.isVerified
          : loggedInUser?.googleId
          ? true
          : loggedInUser?.facebookId
          ? true
          : false,
      },
      order_history:
      userOrders
        ?.sort((a, b) => new Date(b?.product?.[0]?.order_date) - new Date(a?.product?.[0]?.order_date)) // Sort by most recent
        ?.slice(0, 10) // Limit to 10 most recent orders
        ?.map((order) => ({
          purchased_at: order?.product?.[0]?.order_date,
          amount: order?.cancellation_metadata?.updated_total || order?.total,
          status: getHighestRepeatingOrderStatus(order),
          buyer: {
            phone: order?.product?.[0]?.phone,
            email: order?.product?.[0]?.email || loggedInUser?.email,
            name: order?.product?.[0]?.shipping_address?.name,
          },
          shipping_address: {
            city: order?.product?.[0]?.shipping_address?.city,
            address: order?.product?.[0]?.shipping_address?.addressLine1,
            zip: order?.product?.[0]?.shipping_address?.postalCode || "000000",
          },
        })) || [],
    },
    shipping: {
      address: formData.addressLine1,
      city: formData.city,
    },
    items:
      source === "buynow"
        ? [createItemObject(productById, 1)]
        : userCart?.product?.map((item, index) =>
            createItemObject(
              item?.productDetails,
              userCart.product?.[index]?.quantity
            )
          ) || [],
  };
};