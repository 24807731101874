import React from "react";
import ReactDOM from "react-dom/client";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import App from "./App";
import {
  isUserShouldBeAllowed,
  userAccessAllotation,
} from "./Utils/GeoTargeting";
import AdminApp from "./AdminApp";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import "./index.css";

const isAdmin = window.location.pathname.startsWith("/admin");
const root = ReactDOM.createRoot(document.getElementById("root"));
await userAccessAllotation();

root.render(
  <React.StrictMode>
    {isAdmin ? (
      <AdminApp />
    ) : isUserShouldBeAllowed === null || isUserShouldBeAllowed ? (
      <App />
    ) : (
      <div className="loc-restriction">
        <p>
          We are sorry, our services may be limited in your current location.{" "}
          <br />
          We recommend accessing our site from UAE for the best experience.{" "}
        </p>
      </div>
    )}
    <ToastContainer
      position="bottom-right"
      autoClose={3000}
      hideProgressBar={true}
      newestOnTop={false}
      limit={1}
      toastStyle={{
        fontSize: window.innerWidth > 768 ? "1.5vw" : "2vw",
        textAlign: "center",
      }}
      closeOnClick
      className={"toast-container"}
      rtl={false}
      pauseOnFocusLoss={false}
      draggable
      pauseOnHover={false}
    />
  </React.StrictMode>
);
