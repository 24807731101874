export function getHighestRepeatingOrderStatus(order) {
  const statuses = order.product.map((product) => product.order_status);

  // Filter out "cancelled" and count the occurrences of each status
  const statusCount = statuses.reduce((acc, status) => {
    if (status !== "cancelled") {
      acc[status] = (acc[status] || 0) + 1;
    }
    return acc;
  }, {});

  // If all are "cancelled", return "cancelled"
  if (Object.keys(statusCount).length === 0) {
    return "cancelled";
  }

  // Find the status with the highest count
  let highestStatus = null;
  let maxCount = 0;

  for (const [status, count] of Object.entries(statusCount)) {
    if (count > maxCount) {
      maxCount = count;
      highestStatus = status;
    }
  }
  if (highestStatus === "cancelled") return "canceled";
  else if (highestStatus === "delivered") return "complete";
  else if (highestStatus === "confirmed") return "new";
  else if (highestStatus === "refunded") return "refunded";
  else if (highestStatus === "shipped" || highestStatus === "out for delivery")
  return "processing";
  else return "unknown";
}
