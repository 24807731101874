import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import useProductStore from "../Store/productStore";
import useUtilsStore from "../Store/utilsStore";
import StarRating from "../Components/starRating";
import RatingChart from "../Components/RatingChart";
import axios from "axios";
import { toast } from "react-toastify";
import { useIntl } from "react-intl";
import Swal from "sweetalert2";
import { BeatLoader } from "react-spinners";
import "../Styles/productPage/productPage.css";
import TabbyPromo from "../Components/TabbyPromo";
import ProductListSection from "../Components/ProductListSection";

export const ProductPage = () => {
  const { id } = useParams();
  const { productById, getProductById } = useProductStore();
  const {
    isRtl,
    getLoggedInUser,
    loggedInUser,
    getUserCartCount,
    getLocalCartCount,
  } = useUtilsStore();
  const intl = useIntl();
  const [selectedImage, setSelectedImage] = useState(0);
  const [selectedSection, setSelectedSection] = useState("overview");
  const [quantity, setQuantity] = useState(1);
  const [isReady, setIsReady] = useState(false);
  const navigate = useNavigate();
  const accessToken = localStorage.getItem("alambraAccessToken");
  const Server = process.env.REACT_APP_SERVER;
  useEffect(() => {
    getProductById(id);
  }, [id, getProductById]);

  useEffect(() => {
    setIsReady(true);
    window.scrollTo(0, 0);
  }, []);

  const handleSelectedImage = (index) => {
    setSelectedImage(index);
  };

  const handleQuantityChange = (value) => {
    if (quantity + value <= 0) {
      setQuantity(1);
    } else {
      setQuantity(quantity + value);
    }
  };

  const handleAddToCart = async (id) => {
    await getLoggedInUser();
    if (loggedInUser) {
      axios
        .post(
          `/api/cart/add`,
          { productId: id, quantity: quantity },
          {
            headers: {
              Authorization: `access-token ${accessToken}`,
            },
          }
        )
        .then((res) => {
          if (res.data.success) {
            getUserCartCount();
            toast.success(
              `${productById?.name_en} ${productById?.category_en} ${
                productById?.quantity
              } of quantity ${quantity} ${res.data.message.toLowerCase()}`
            );
          }
        })
        .catch((err) => {
          toast.error(err.response.data.message);
          if (err.response.status === 401) {
            navigate("/login");
          }
        });
    } else {
      try {
        const localCart = await JSON.parse(localStorage.getItem("alambraCart"));
        if (localCart) {
          const existingProduct = await localCart.find(
            (product) => product.productId.toString() === id
          );
          if (existingProduct) {
            existingProduct.quantity += quantity;
            localStorage.setItem("alambraCart", JSON.stringify(localCart));
          } else {
            localCart.push({
              productId: id,
              quantity: quantity,
            });
            localStorage.setItem("alambraCart", JSON.stringify(localCart));
          }
        } else {
          localStorage.setItem(
            "alambraCart",
            JSON.stringify([
              {
                productId: id,
                quantity: quantity,
              },
            ])
          );
        }
        getLocalCartCount();
        toast.success(
          `${productById?.name_en} ${productById?.category_en} ${productById?.quantity} of quantity ${quantity} added to cart`
        );
      } catch (error) {
        console.log(error);
      }
    }
  };

  const handleBuyNow = async () => {
    await getLoggedInUser();

    if (loggedInUser) {
      navigate(`/checkout/buynow/${productById?._id}`);
    } else {
      Swal.fire({
        title: "Please login to checkout",
        icon: "warning",
      }).then((result) => {
        if (result.isConfirmed) {
          navigate("/login", { state: { from: `/product/${id}` } });
        }
      });
    }
  };

  return (
    <>
      {isReady && productById?._id === id ? (
        <>
          <div className="product-container">
            <div id="product-images" className="product-images">
              <div className="product-main-image-container">
                {productById?.out_of_stock && (
                  <div className="outOfStockOverlay">Out of Stock</div>
                )}
                <img
                  src={`${Server}${productById?.rawImages[selectedImage]?.path}`}
                  alt=""
                />
              </div>

              <div className="product-secondary-images-container">
                {productById?.rawImages?.map((item, index) => (
                  <div
                    key={index}
                    className={` image-container ${
                      index === selectedImage ? "selected" : ""
                    }`}
                  >
                    <img
                      src={`${Server}${item?.path}`}
                      alt=""
                      onClick={() => handleSelectedImage(index)}
                    />
                  </div>
                ))}
              </div>
            </div>
            <div className="product-purchase-content">
              <p className="productTitle">
                {isRtl ? productById?.name_ar : productById?.name_en}
              </p>
              <p>
                {(isRtl
                  ? productById?.category_ar.toUpperCase()
                  : productById?.category_en.toUpperCase()) +
                  " " +
                  productById?.quantity}
              </p>
              <div className="ratingContainer">
                <p>{productById?.average_rating}</p>
                <StarRating
                  initialRating={productById?.average_rating}
                  readonly={true}
                />
                <p>
                  ({productById?.reviewCount}{" "}
                  {intl.formatMessage({
                    id: "product.customerreview",
                  })}
                  {productById?.reviewCount > 1
                    ? `${intl.formatMessage({
                        id: "product.customerreview.s",
                      })}`
                    : ""}
                  )
                </p>
              </div>

              <h6>AED {productById?.price}</h6>

              {productById?.notes && (
                <div className="per-notes">
                  <p id="note-head">Notes:</p>
                  <p className="notes">
                    Top:{" "}
                    {productById?.notes?.top?.map((item, index) => (
                      <span key={index} className="note-name">
                        {item}
                        {index + 1 !== productById?.notes?.top?.length
                          ? ", "
                          : ""}
                      </span>
                    ))}
                  </p>
                  <p className="notes">
                    Heart:{" "}
                    {productById?.notes?.heart?.map((item, index) => (
                      <span key={index} className="note-name">
                        {item}
                        {index + 1 !== productById?.notes?.heart?.length
                          ? ", "
                          : ""}
                      </span>
                    ))}{" "}
                  </p>
                  <p className="notes">
                    Base:{" "}
                    {productById?.notes?.base?.map((item, index) => (
                      <span key={index} className="note-name">
                        {item}
                        {index + 1 !== productById?.notes?.base?.length
                          ? ", "
                          : ""}
                      </span>
                    ))}{" "}
                  </p>
                </div>
              )}

              <div className="action1">
                <div className="product-quantity-wrapper">
                  <p>{intl.formatMessage({ id: "product.quantity" })}</p>
                  <div className="product-quantity">
                    <button onClick={() => handleQuantityChange(-1)}>-</button>
                    <span>{quantity}</span>
                    <button onClick={() => handleQuantityChange(1)}>+</button>
                  </div>
                </div>
                <button
                  className={`addToCartBtn ${
                    productById?.out_of_stock ? "disabledBtn" : ""
                  }`}
                  disabled={productById?.out_of_stock}
                  onClick={() => handleAddToCart(productById?._id)}
                >
                  {productById?.out_of_stock ? "Out of Stock" : intl.formatMessage({ id: "product.addToCart" })}
                </button>
              </div>
              <div className="offer-section">
                <TabbyPromo price={productById?.price}/>
              </div>
              <div className="product-actions">
                <button
                  disabled={productById?.out_of_stock}
                  onClick={handleBuyNow}
                  className={` ${productById?.out_of_stock ? 'disabledBtn' : ''}`}

                >
                  {productById?.out_of_stock
                    ? "Out of Stock"
                    : intl.formatMessage({ id: "product.buyNow" })}
                </button>
              </div>
              
            </div>
          </div>
          <div className="product-details">
            <div className="action-btns">
              <div className="overview-btn">
                <button
                  className={selectedSection === "overview" ? "selected" : ""}
                  onClick={() => setSelectedSection("overview")}
                >
                  {intl.formatMessage({ id: "product.overview" })}
                </button>
              </div>
              <div className="review-btn">
                <button
                  className={selectedSection === "reviews" ? "selected" : ""}
                  onClick={() => setSelectedSection("reviews")}
                >
                  {intl.formatMessage({ id: "product.rating&reviews" })}
                </button>
              </div>
            </div>
            {selectedSection === "overview" && (
              <div className="overview">
                {isRtl
                  ? productById?.description_ar
                  : productById?.description_en}
              </div>
            )}
            {selectedSection === "reviews" && (
              <div className="rating-n-review-section">
                <div className="rating-section">
                  <div className="rating">
                    <h1>{productById?.average_rating}</h1>
                    <StarRating
                      initialRating={productById?.average_rating}
                      readonly={true}
                    />
                    <p>
                      {productById?.ratingCount}{" "}
                      {intl.formatMessage({
                        id: "product.rating",
                      })}
                      {productById?.ratingCount > 1
                        ? `${intl.formatMessage({ id: "product.rating.s" })}`
                        : ""}{" "}
                      &
                    </p>
                    <p>
                      {productById?.reviewCount}{" "}
                      {intl.formatMessage({
                        id: "product.review",
                      })}
                      {productById?.reviewCount > 1
                        ? `${intl.formatMessage({ id: "product.review.s" })}`
                        : ""}
                    </p>
                  </div>

                  <div className="chart">
                    <RatingChartContainer ratingData={productById?.ratings} />
                  </div>
                </div>
                <div className="review-section">
                  <div className="review-head">
                    <h4>{intl.formatMessage({ id: "product.reviews" })}</h4>
                    <button
                      onClick={() => navigate(`/rating-and-review/${id}`)}
                      className="rate-btn"
                    >
                      {intl.formatMessage({ id: "product.rateProduct" })}
                    </button>
                  </div>
                  {productById?.reviews?.length > 0 ? (
                    <>
                      <div className="reviewBoxes">
                        {productById?.reviews?.map((item, index) => (
                          <div className="reviewBox" key={index}>
                            <h5 className="name">
                              {item?.userId?.username ||
                                item?.userId?.name_google ||
                                item?.userId?.name_facebook}
                            </h5>
                            <p className="review-content">{item?.review}</p>
                          </div>
                        ))}
                      </div>
                      <button>
                        {intl.formatMessage({ id: "product.button.viewmore" })}
                      </button>
                    </>
                  ) : (
                    <p>No Reviews found!</p>
                  )}
                </div>
              </div>
            )}
          </div>
          <ProductListSection />
        </>
      ) : (
        <div className="loadingIndicator">
          <h5>Loading</h5>
          <BeatLoader color="#3498db" size={"1.5vw"} />
        </div>
      )}
    </>
  );
};

const RatingChartContainer = ({ ratingData }) => {
  const starArray = [5, 4, 3, 2, 1];
  const ratings = starArray.map((rating) => {
    return {
      stars: rating,
      count: ratingData.find((item) => item._id === rating)?.count ?? 0,
    };
  });

  return (
    <>
      <RatingChart ratings={ratings} />
    </>
  );
};
