export const tabbyRejectionMessageHandler = (reason) => {
    if (reason === "not_available") {
      return {en : "Sorry, Tabby is unable to approve this purchase.\nPlease use an alternative payment method for your order.",
        ar : "نأسف، تابي غير قادرة على الموافقة على هذه العملية. \nالرجاء استخدام طريقة دفع أخرى."
      };
    } else if (reason === "order_amount_too_high") {
      return {en:"This purchase is above your current spending limit with Tabby.\nTry a smaller cart or use another payment method.",
         ar: "قيمة الطلب تفوق الحد الأقصى المسموح به حاليًا مع تابي. \n يُرجى تخفيض قيمة السلة أو استخدام وسيلة دفع أخرى."
      };
    } else if (reason === "order_amount_too_low") {
      return {en:"The purchase amount is below the minimum amount required to use Tabby.\nTry adding more items or use another payment method.",
        ar: "قيمة الطلب أقل من الحد الأدنى المطلوب لاستخدام خدمة تابي. \n يُرجى زيادة قيمة الطلب أو استخدام وسيلة دفع أخرى."
      };
    }
  };
  