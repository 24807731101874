import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import axios from "axios";
import useUtilsStore from "../Store/utilsStore";
import { useIntl } from "react-intl";
import "../Styles/PaymentSuccess/PaymentSuccess.css";

export const PaymentSuccess = () => {
  const [statusMessage, setStatusMessage] = useState(
    "Fetching payment status..."
  );
  const intl = useIntl();
  const location = useLocation();
  const {getUserCartCount} = useUtilsStore();
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const source = queryParams.get("source");
    const paymentId = queryParams.get("payment_id");

    if (source === "tabby") {
      if (paymentId) {
        // Handle Tabby specific logic
        checkPaymentStatus(paymentId);
      } else {
        // Payment ID is missing
        setStatusMessage(intl.formatMessage({ id: "paymentIdMissing.message" }));
      }
    } else if (source) {
      // Source is present but not 'tabby'
      setStatusMessage(intl.formatMessage({ id: "paymentIdInvalid.message" }));
    } else {
      // Handle generic success message for other gateways
      setStatusMessage(
        intl.formatMessage({ id: "paymentSuccess.message" })
      );
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, intl]);
  const checkPaymentStatus = async (paymentId) => {
    try {
      const response = await axios.get(
        `/api/tabby/retrieve-payment/${paymentId}`
      );
      const { status } = response.data;
      if (status === "AUTHORIZED") {
        setStatusMessage(
          intl.formatMessage({ id: "paymentProcessing.message" })
        );
        // order processing here ...
        try {
          await axios.post(`/api/tabby-orders/create-order/${paymentId}`);
          setStatusMessage(intl.formatMessage({ id: "paymentSuccess.message" }));
          getUserCartCount();
        } catch (error) {
          setStatusMessage(intl.formatMessage({ id: "paymentOrderCreationFailed.message" }));
          console.error("Order creation error:", error);
        }
      } else if (status === "CLOSED") {
        setStatusMessage(intl.formatMessage({ id: "paymentProcessed.message" }));
      }else {
        setStatusMessage(intl.formatMessage({ id: "paymentError.message" }));
      }
    } catch (error) {
      setStatusMessage("Error retrieving payment status.");
      console.error("Error:", error);
    }
  };

  return (
    <div className="paymentSuccessContainer">
      <h3>{intl.formatMessage({id:"paymentSuccess.header"})}</h3>
      <p>{statusMessage}</p>
    </div>
  );
};
