// PaymentCancel.js
import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import "../Styles/PaymentCancel/PaymentCancel.css";
import { useIntl } from "react-intl";

export const PaymentCancel = () => {
  const location = useLocation();
  const [statusMessage, setStatusMessage] = useState();
  const intl = useIntl();
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const source = queryParams.get("source");
    if (source && source === "tabby") {
      setStatusMessage(
        intl.formatMessage({id:"paymentCancel.message"})
      );
    }
  }, [location, intl]);

  return (
    <div className="paymentCancelContainer">
      <h3>{intl.formatMessage({id:"paymentCancel.header"})}</h3>
      <p>
        {statusMessage
          ? statusMessage
          : intl.formatMessage({id:"paymentCancelled.message"})}
      </p>
    </div>
  );
};
