import React, { useEffect, useState } from "react";
import delAddressValidationSchema from "../Schemas/delAddressValidation";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import * as Yup from "yup";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import useUtilsStore from "../Store/utilsStore";
import useProductStore from "../Store/productStore";
import "../Styles/Checkout/Checkout.css";
import { useIntl } from "react-intl";
import { createTabbyCheckoutObject } from "../Utils/TabbyCheckoutObjectMaker";
import TabbyCard from "../Components/TabbyCard";
import TabbyInfoButton from "../Components/TabbyInfoButton";
import PaymentIcon from "@mui/icons-material/Payment";
import MoneyIcon from "@mui/icons-material/Money";
import Tabby from "../SVG/Tabby.svg";
import { PreDataMaker } from "../Utils/CheckoutPreDataMaker";
import { tabbyRejectionMessageHandler } from "../Utils/TabbyRejectonMessageHandler";

const Checkout = () => {
  const { source, productId } = useParams();
  const { loggedInUser, getLoggedInUser, isRtl } = useUtilsStore();
  const { userOrders, getUserOrders } = useProductStore();

  useEffect(() => {
    getUserOrders();
  }, [getUserOrders]);
  const navigate = useNavigate();
  const intl = useIntl();

  const [price, setPrice] = useState({
    subtotal: 0,
    tax: 0,
    shipping: null,
    total: 0,
  });
  const initialTabbyAvailable = process.env.REACT_APP_TABBY_ENVIRONMENT === 'test'
  const [productById, setProductById] = useState();
  const [userCart, setUserCart] = useState();
  const [tabbyAvailable, setTabbyAvailable] = useState(initialTabbyAvailable ? true : false);
  const [inputsEnabled, setInputsEnabled] = useState(false);
  const [validationErrors, setValidationErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isTabbySelected, setIsTabbySelected] = useState(false);
  const [tabbyRejectionText, setTabbyRejectionText] = useState("");
  const [formData, setFormData] = useState({
    name: "",
    addressLine1: "",
    city: "",
    state: "",
    country: "United Arab Emirates",
    phone: "",
    paymentMethod: "",
  });
  const lang = isRtl ? "ar" : "en";

  // Function to make prescore call with provided form data
  async function handlePrescoreCall(currentFormData) {
    const data = PreDataMaker(
      source,
      price,
      currentFormData,
      loggedInUser,
      userOrders,
      productById,
      userCart
    );
    const body = createTabbyCheckoutObject({ ...data, lang });
    console.log("Tabby Checkout Object", body);


    try {
      const response = await axios.post("/api/tabby/create-checkout", body, {
        headers: {
          Authorization: `access-token ${localStorage.getItem(
            "alambraAccessToken"
          )}`,
        },
      });
      if (response.data?.status === "created") {
        setTabbyAvailable(true);
      } else if (response.data?.status === "rejected") {
        setTabbyAvailable(false);
        setTabbyRejectionText(
          response.data.configuration.products.installments.rejection_reason
        );
      }
      else {
        console.log("response", response.data);
      }
    } catch (error) {
      console.error("Error during prescore call:", error);
      setTabbyAvailable(false);
    }
  }

  // Debounce logic for form data updates and trigger handlePrescoreCall
  useEffect(() => {
    const handler = setTimeout(() => {

      const { paymentMethod, ...shippingData } = formData;

      if (Object.values(shippingData).every(value => value) && inputsEnabled) {
        handlePrescoreCall(formData);
      }
    }, 500); // 500ms debounce delay

    return () => clearTimeout(handler); // Cleanup debounce timeout
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData, inputsEnabled]);




  const finalValidationSchema = delAddressValidationSchema.shape({
    paymentMethod: Yup.string().required("Please select payment method"),
  });

  useEffect(() => {
    const validateForm = async () => {
      const { paymentMethod, ...formDataWithoutPaymentMethod } = formData;
      try {
        await delAddressValidationSchema.validate(
          formDataWithoutPaymentMethod,
          {
            abortEarly: false,
          }
        );
        setInputsEnabled(true); // Validation passed, enable inputs
      } catch (err) {
        setInputsEnabled(false); // Validation failed, disable inputs
      }
    };

    validateForm();
  }, [formData]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (source === "buynow" && productId) {
          const res = await axios.get(`/api/products/${productId}`);
          setProductById(res?.data?.product);
          const priceSum = res?.data?.product?.price;
          const shippingCharge =
            Number(priceSum) >= Number(500) ? Number(0) : Number(25);
          setPrice({
            netPrice: (Number(priceSum) * Number(0.95)).toFixed(2),
            subtotal: Number(priceSum),
            tax: (Number(priceSum) * Number(0.05)).toFixed(2),
            shipping: Number(shippingCharge),
            total: Number(priceSum) + Number(shippingCharge),
          });
        } else if (source === "cart" && !productId) {
          const res = await axios.get(`/api/cart/userCart`, {
            headers: {
              Authorization: `access-token ${localStorage.getItem(
                "alambraAccessToken"
              )}`,
            },
          });
          setUserCart(res?.data?.userCart);
          const priceSum = await res?.data?.userCart?.product?.reduce(
            (acc, item) => acc + item.productDetails.price * item.quantity,
            0
          );
          const shippingCharge =
            Number(priceSum) >= Number(500) ? Number(0) : Number(25);
          setPrice({
            netPrice: (Number(priceSum) * Number(0.95)).toFixed(2),
            subtotal: Number(priceSum),
            tax: (Number(priceSum) * Number(0.05)).toFixed(2),
            shipping: Number(shippingCharge),
            total: Number(priceSum) + Number(shippingCharge),
          });
        } else {
          navigate("/not-found");
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, [navigate, productId, source]);

  useEffect(() => {
    getLoggedInUser();
  }, [getLoggedInUser]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;

    // Update form data
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));

    // Validate current field
    finalValidationSchema
      .validateAt(name, { [name]: value })
      .then(() => {
        setValidationErrors((prevErrors) => ({
          ...prevErrors,
          [name]: undefined,
        }));
      })
      .catch((error) => {
        setValidationErrors((prevErrors) => ({
          ...prevErrors,
          [name]: error.message,
        }));
      });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsSubmitting(true);
    try {
      await finalValidationSchema.validate(formData, {
        abortEarly: false,
      });
    } catch (error) {
      setIsSubmitting(false);
      handleValidationErrors(error);
      console.log(error);
      return;
    }

    const body = {
      source,
      [source === "buynow" ? "product" : "cartId"]:
        source === "buynow" ? productById : userCart?._id,
    };

    try {
      if (formData.paymentMethod === "cashOnDelivery") {
        setIsSubmitting(true);
        const response = await axios.post(
          `/api/orders/create`,
          {
            formData,
            ...body,
            subtotal: Number(price?.subtotal),
            shipping_charge: Number(price?.shipping),
            total: Number(price?.total),
          },
          {
            headers: {
              Authorization: `access-token ${localStorage.getItem(
                "alambraAccessToken"
              )}`,
            },
          }
        );

        if (response.data.success) {
          toast.success(response.data.message);
          navigate("/orders");
        }
      } else if (formData.paymentMethod === "online") {
        setIsSubmitting(true);
        const payload = { ...body, shipping_address: formData };
        Swal.fire({
          title: "Online Payment",
          text: "Redirecting to payment gateway...",
          icon: "info",
          showConfirmButton: false, // Hide the OK button
          timer: 3000, // Auto-close after 3 seconds
        });
        axios
          .post("/api/payment/stripe/checkout", payload, {
            headers: {
              Authorization: `access-token ${localStorage.getItem(
                "alambraAccessToken"
              )}`,
            },
          })
          .then((res) => {
            if (res.data.success) {
              window.location.href = res.data.url;
            }
          });
      } else if (formData.paymentMethod === "tabby") {
        setIsSubmitting(true);
        await getUserOrders();
        Swal.fire({
          title: "Online Payment",
          text: "Redirecting to payment gateway...",
          icon: "info",
          showConfirmButton: false, // Hide the OK button
          timer: 3000, // Auto-close after 3 seconds
        });
        const data = PreDataMaker(
          source,
          price,
          formData,
          loggedInUser,
          userOrders,
          productById,
          userCart
        );
        const body = createTabbyCheckoutObject({ ...data, lang });
        console.log("Tabby Checkout Object", body);

        const response = await axios.post("/api/tabby/create-checkout", body, {
          headers: {
            Authorization: `access-token ${localStorage.getItem(
              "alambraAccessToken"
            )}`,
          },
        });
        if (response.data) {
          window.location.href =
            response.data?.configuration?.available_products?.installments?.[0]?.web_url;
        }
      }
    } catch (error) {
      setIsSubmitting(false);
      toast.error(
        error?.response ? error?.response?.data?.message : error?.message
      );
    }
  };
  const handleValidationErrors = (error) => {
    const errors = {};

    error.inner.forEach((err) => {
      errors[err.path] = err.message;
    });

    setValidationErrors(errors);
  };

  const handleRadioChange = (event) => {
    handleInputChange(event);
    const { value } = event.target;
    value === "tabby" ? setIsTabbySelected(true) : setIsTabbySelected(false);
  };



  return (
    <form className="del-form">
      <div className="checkout-container">
        <div className="deliveryAddress">
          <h4>{intl.formatMessage({ id: "checkout.deliveryHeader" })}</h4>
          <div className="division">
            <div>
              <input
                className="fullname"
                name="name"
                type="text"
                placeholder={`${intl.formatMessage({ id: "checkout.fullName" })}*`}
                defaultValue={formData.name}
                onChange={handleInputChange}
                autoFocus
                autoComplete="off"
              />
              {validationErrors.name && (
                <div className="error-msg">{validationErrors.name}</div>
              )}
            </div>
          </div>
          <div className="addressSection">
            <div>
              <textarea
                className="address1"
                name="addressLine1"
                type="text"
                placeholder={`${intl.formatMessage({ id: "checkout.address" })}*`}
                defaultValue={formData.addressLine1}
                onChange={handleInputChange}
                autoComplete="off"
                rows="3"
                style={{ resize: "vertical" }}
              />
              {validationErrors.addressLine1 && (
                <div className="error-msg">{validationErrors.addressLine1}</div>
              )}
            </div>
          </div>

          <div className="division">
            <div>
              <input
                className="city"
                type="text"
                name="city"
                placeholder={`${intl.formatMessage({ id: "checkout.city" })}*`}
                defaultValue={formData.city}
                onChange={handleInputChange}
                autoComplete="off"
              />
              {validationErrors.city && (
                <div className="error-msg">{validationErrors.city}</div>
              )}
            </div>

            <div>
              <select
                className="state"
                name="state"
                defaultValue={formData.state}
                onChange={handleInputChange}
              >
                <option disabled value="">
                  {intl.formatMessage({ id: "checkout.optionsLabel" })}*
                </option>
                <option value="Abu Dhabi">{intl.formatMessage({ id: "checkout.state.options.abudhabi" })}</option>
                <option value="Dubai">{intl.formatMessage({ id: "checkout.state.options.dubai" })}</option>
                <option value="Sharjah">{intl.formatMessage({ id: "checkout.state.options.sharjah" })}</option>
                <option value="Ajman">{intl.formatMessage({ id: "checkout.state.options.ajman" })}</option>
                <option value="Fujairah">{intl.formatMessage({ id: "checkout.state.options.fujairah" })}</option>
                <option value="Ras Al Khaimah">{intl.formatMessage({ id: "checkout.state.options.rasalkhaimah" })}</option>
                <option value="Umm Al Quwain">{intl.formatMessage({ id: "checkout.state.options.ummalquwain" })}</option>
              </select>
              {validationErrors.state && (
                <div className="error-msg">{validationErrors.state}</div>
              )}
            </div>
          </div>
          <div className="division">
            <div>
              <input
                className="country"
                type="text"
                name="country"
                placeholder={`${intl.formatMessage({ id: "checkout.country" })}*`}
                defaultValue={formData.country}
                readOnly
                autoComplete="off"
                disabled
              />
              {validationErrors.country && (
                <div className="error-msg">{validationErrors.country}</div>
              )}
            </div>
            <div>
              <div className="mobileNumber">
                <input
                  className="country-code"
                  type="text"
                  name="countryCode"
                  placeholder={`${intl.formatMessage({ id: "checkout.countryCode" })}*`}
                  value={"+971"}
                  disabled
                  autoComplete="off"
                />
                <input
                  className="phone"
                  type="tel"
                  name="phone"
                  placeholder={`${intl.formatMessage({ id: "checkout.phone" })}*`}
                  defaultValue={formData.phone}
                  onChange={handleInputChange}
                  autoComplete="off"
                />
              </div>

              {validationErrors.phone && (
                <div className="error-msg">{validationErrors.phone}</div>
              )}
            </div>
          </div>
        </div>
        <div className="right-section">
          <div className="payment-method-section">
            <div className="pay-method">
              <input
                type="radio"
                name="paymentMethod"
                value="online"
                onChange={handleRadioChange}
                disabled={!inputsEnabled}
                id="online"
              />
              <label htmlFor="online">
                <PaymentIcon /> {intl.formatMessage({ id: "checkout.paymentMethod.online" })}
              </label>
            </div>

            <div className="pay-method">
              <input
                type="radio"
                name="paymentMethod"
                value={"cashOnDelivery"}
                id="cashOnDelivery"
                onChange={handleRadioChange}
                disabled={!inputsEnabled}
              />
              <label htmlFor="cashOnDelivery">
                <MoneyIcon /> {intl.formatMessage({ id: "checkout.paymentMethod.cash" })}
              </label>
            </div>

            <div className="pay-method tabby-section">
              <input
                type="radio"
                name="paymentMethod"
                value={"tabby"}
                id="tabbyPayLater"
                onChange={handleRadioChange}
                disabled={!inputsEnabled || !tabbyAvailable || !initialTabbyAvailable}
                onClick={() => handlePrescoreCall(formData)}
              />
              <label htmlFor="tabbyPayLater">
                <img className="tabby-image" src={Tabby} alt="" />
                {intl.formatMessage({ id: "checkout.paymentMethod.tabby" })}
                {price && <TabbyInfoButton price={price?.total} />}
              </label>
            </div>
            {price && isTabbySelected && tabbyAvailable && (
              <TabbyCard price={price?.total} />
            )}
            {!tabbyAvailable && tabbyRejectionText && (
              <div className="error-msg">
                {tabbyRejectionMessageHandler(tabbyRejectionText)[lang]}
              </div>
            )}
            {validationErrors.paymentMethod && (
              <div className="error-msg">{validationErrors.paymentMethod}</div>
            )}
          </div>
          <div className="place-order-section">
            <div className="order-summary">
              <h4>{intl.formatMessage({ id: "checkout.header.orderSummary" })}</h4>
              <div className="subtotal">
                <span>{intl.formatMessage({ id: "checkout.subtotal.netPrice" })}</span>
                <span>
                  {price?.netPrice ? `AED ${price?.netPrice}` : "..."}
                </span>
              </div>
              <div className="tax">
                <span>{intl.formatMessage({ id: "checkout.tax.vat" })}</span>
                <span>{price?.tax ? `AED ${price?.tax}` : "..."}</span>
              </div>
              <div className="shipping">
                <span>{intl.formatMessage({ id: "checkout.shipping.shippingCharge" })}</span>
                <span>
                  {price?.shipping === 0
                    ? "Free"
                    : price?.shipping !== undefined && price?.shipping !== null
                      ? `AED ${price?.shipping}`
                      : "..."}
                </span>
              </div>
              <div className="total">
                <span>{intl.formatMessage({ id: "checkout.total" })}</span>
                <span>{price?.total ? `AED ${price?.total}` : "..."}</span>
              </div>
            </div>
            <div className="place-order">
              <button onClick={handleSubmit} disabled={isSubmitting}>
                {isSubmitting ? intl.formatMessage({ id: "checkout.button.processing" }) : intl.formatMessage({ id: "checkout.button.proceed" })}
              </button>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

export default Checkout;
