import React, { useEffect, useRef } from 'react';
import useUtilsStore from '../Store/utilsStore';

const TabbyPromo = ({ price, currency = 'AED', installmentsCount = 4, source = 'product' }) => {
  const { isRtl } = useUtilsStore();
  const containerRef = useRef(null); // Ref to store the TabbyPromo instance
  // Determine the language based on the isRtl value
  let lang = isRtl ? 'ar' : 'en';

  useEffect(() => {
    const container = containerRef.current;
     // Clear the container's content
    if (container) {
      container.innerHTML = ''; // Remove all child elements
    }

    // Create a new div for TabbyPromo to attach to
    const tabbyContainer = document.createElement('div');
    tabbyContainer.id = 'TabbyPromo';
    container.appendChild(tabbyContainer);
     
       // Initialize a new TabbyPromo instance
    new window.TabbyPromo({
      selector: '#TabbyPromo',
      currency: currency, // AED|SAR|KWD|BHD|QAR only supported, with no spaces or lowercase.
      price: price.toFixed(2), // price or the product. 2 decimals max for AED|SAR|QAR and 3 decimals max for KWD|BHD.
      installmentsCount: installmentsCount, // Optional, for non-standard plans.
      lang: lang, // Optional, language of snippet and popups, if the property is not set, then it is based on the attribute 'lang' of your html tag.
      source: source, // Optional, snippet placement; `product` for product page and `cart` for cart page.
      publicKey: process.env.REACT_APP_TABBY_PUBLIC_TEST_KEY, // required, store Public Key which identifies your account when communicating with tabby.
      merchantCode: process.env.REACT_APP_TABBY_MERCHANT_CODE // required
    });
    
    // Cleanup function to remove the container's content when the component unmounts or dependencies change
    return () => {
      if (container) {
        container.innerHTML = ''; // Clear the container
      }
    };

  }, [price, currency, installmentsCount, source, lang]);

  return (
    <div ref={containerRef}></div>
  );
};

export default TabbyPromo;
