

export const createTabbyCheckoutObject = (data) => {
  
  return {
    payment: {
      amount: data.total.toString(),
      currency: "AED",
      description: data.source,
      buyer: {
        phone: data.customer.phone,
        email: data.customer?.email,
        name: data.customer.name,
      },
      shipping_address: {
        address: data.shipping.address,
        city: data.shipping.city,
      },
      order: {
        tax_amount: data.tax.toString(),
        shipping_amount: data.shipping_price.toString(),
        discount_amount: "0.00",
        updated_at: new Date(),
        reference_id: `ref-${Date.now()}`,
        items: data.items,
      },
      buyer_history:data.customer.history,
      order_history:data.customer.order_history,
      meta:{
        customer:data.customer.id
      }
    },
    lang: data.lang,
    merchant_code: process.env.REACT_APP_TABBY_MERCHANT_CODE,
    merchant_urls: {
      success: process.env.REACT_APP_TABBY_SUCCESS_URL,
      cancel:  process.env.REACT_APP_TABBY_CANCEL_URL,
      failure: process.env.REACT_APP_TABBY_FAILURE_URL,
    },
    token: null,
  };
};
