import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import "../Styles/PaymentFailure/PaymentFailure.css";
import { useIntl } from "react-intl";

const PaymentFailure = () => {
  const location = useLocation();
  const [statusMessage, setStatusMessage] = useState();
  const intl = useIntl();
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const source = queryParams.get("source");
    if (source && source === "tabby") {
      setStatusMessage(
        intl.formatMessage({ id: "paymentFailure.message" })
      );
    }
  }, [location,intl]);
  return (
    <div className="paymentFailureContainer">
      <h3>{intl.formatMessage({id:"paymentFailure.header"})}</h3>
      <p>
        {statusMessage
          ? statusMessage
          : intl.formatMessage({ id:"orderFailure.message" })}
      </p>
    </div>
  );
};

export default PaymentFailure;
