import React, { useEffect } from "react";
const TabbyInfoButton = ({ price, currency = "AED" }) => {
  useEffect(() => {
    new window.TabbyPromo({}); 
  }, []);

  return (
    <button
      data-tabby-info="installments"
      data-tabby-price={price.toFixed(2)}
      data-tabby-currency={currency}
      style={{
        border:  "1px solid black",
        width:"fit-content",
        height:"fit-content ",
        borderRadius: "50%",
        fontSize:"10px",
        margin:"0 5px",
        cursor: "pointer",
        textAlign: "center",
        backgroundColor: "black",
        color: "#fff",
        fontStyle:"oblique"
      }}
    >
     i
    </button>
  );
};

export default TabbyInfoButton;
